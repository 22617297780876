import { useStore } from "vuex";
import { computed, watchEffect , ref as refVue , onBeforeMount } from "vue";
import { ref as refDb, set, onValue , get , update  , child} from "firebase/database";
import database from "../../../../firebase/config";
import { useRouter } from "vue-router";

const useLibrary = () => {
  const store = useStore();
  const router = useRouter()
  const setInfo = refVue(false)
  const isCompleted = refVue(false)
  const isLoadingMarked = refVue(false)
  onBeforeMount(() => {
    const library = store.getters["shared/getCurrentLibrary"];
    if(library.isLoading)
      router.push({
        name: "student-library",
      });
  })
  const setLevels = (studentInfo, library) => {
    let info = studentInfo;
    let levels = library.levels
    const dbRef = refDb(database, "Spanish/" + info.token_firebase);
    onValue(dbRef, async (snapshot) => {
      const logout_student = store.getters["students/logout_student"];
      if (!logout_student.value) {
        let dataProgress = [];
        snapshot.forEach((child) => {
          dataProgress.push(child.val());
        });
        levels.forEach((level, index) => {
          level.complete = dataProgress[index]?.complete;
          level.all = dataProgress[index]?.all;
          level.level_content.forEach((unit, index2) => {
            unit.complete = dataProgress[index][index2]?.complete;
            unit.all = dataProgress[index][index2]?.all;
            let hash = {};
            unit.title_content = unit.title_content.filter(title => hash[title.subtitle_id] ? false : hash[title.subtitle_id] = true)
            unit.title_content.forEach((title, index3) => {
              let titles_states = dataProgress[index][index2]?.subtitles
              if(titles_states !== undefined)
                title.status = titles_states[index3]?.status === 1 ? true : false;
            });
          });
        });
        store.commit(
          "shared/updateLibrary",
          {
            levels:levels,
            isLoading:false
          }
        );
      } else {
        store.commit("students/logout_student", false);
      }
    });
  };

  const loadServices = async () => {
    await store.dispatch("students/dashboardStart");
    if(!setInfo.value)
      await store.dispatch("students/libraryLevels");
  };

  const markedCompleted =  async (current_level,level_name_short) => {
    isLoadingMarked.value = true
    const user = store.getters["shared/getUserAuthentication"];
    const library = store.getters["shared/getCurrentLibrary"];

    const dbRef = refDb(
      database,
      "Spanish/" + user.token_firebase + "/" + library.currentLevel.level_name_short + "/" + library.currentLevelContent.indexLevel + "/subtitles/" + library.currentLevelContent.indexSubtitle
    );

    const dbRefChange = refDb(
      database,
    );
    isCompleted.value = !isCompleted.value
    
    update(dbRef, { status: isCompleted.value ? 1 : 0 });
    
    get(child(dbRefChange,  "Spanish/" + user.token_firebase + "/" + level_name_short)).then((snapshot) => {
      let data = snapshot.val();
      const refLevel = refDb(
        database,
        "Spanish/" + user.token_firebase + "/" + level_name_short
      );
      const refTitle = refDb(
        database,
        "Spanish/" + user.token_firebase + "/" + level_name_short + "/" + current_level.indexLevel 
      );
      update(refTitle  , { complete : isCompleted.value ?  Math.abs(data[current_level.indexLevel].complete + 1) : Math.abs(data[current_level.indexLevel].complete - 1)});
      update(refLevel  , { complete : isCompleted.value ? Math.abs(data.complete + 1) :  Math.abs(data.complete - 1)});
    }).catch((error) => {
      console.error(error);
    });
  


    await store.dispatch("students/markedCompleted",{level_info:{...current_level , level_name_short},completed:isCompleted.value});

    isLoadingMarked.value = false
  }

  watchEffect(() => {
    const studentInfo = store.getters["students/studentInformation"];
    const library = store.getters["shared/getCurrentLibrary"];
    if (studentInfo?.token_firebase && library.levels.length > 0 && !setInfo.value) {
      setInfo.value = true
      setLevels(studentInfo, library);
    }
  });

  const statusSubtitle = () => {
    const library = store.getters["shared/getCurrentLibrary"];
    const user = store.getters["shared/getUserAuthentication"];
     const dbRef = refDb(
        database,
        "Spanish/" + user.token_firebase + "/" + library.currentLevel.level_name_short + "/" + library.currentLevelContent.indexLevel + "/subtitles/" + library.currentLevelContent.indexSubtitle
      );
      onValue(dbRef, async (snapshot) => {
        let status = snapshot.val().status === 1 ? true : false;
        isCompleted.value = status
        update(dbRef, { status: status ? 1 : 0 });
      });
    
  }
  return {
    loadServices,
    markedCompleted,
    statusSubtitle,
    isLoadingMarked,
    isCompleted
  };
};

export default useLibrary;
