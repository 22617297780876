import { computed, ref , watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";
import getUserAuthenticated from "@/shared/helpers/getUserAuthenticated";

const useCurrentLibrary = () => {
  const router = useRouter();
  const store = useStore();
  const { isLogged, role, token } = getUserAuthenticated();
  const showLibrary = ref(true)
  

  const goLevelContent = (title, content , indexLevel ,title_id , indexSubtitle) => {
    // console.log(title, content , indexLevel ,title_id , indexSubtitle)
    store.commit("shared/updateCurrentLevel", {
      currentLevelContent: {
        content,
        title,
        title_id,
        indexLevel,
        indexSubtitle
      },
    });
    if(role === "teacher")
      router.push({
        name: "teacher-library-content",
      });
    else if(role === "student")
      router.push({
        name: "student-library-content",
      });
    else
      router.push({
        name: "admin-library-content",
      });
      
  };

  const goPreviousLibrary = () => {
    if(role === "teacher")
    router.push({
      name: "teacher-library",
    });
  else if(role === "student")
    router.push({
      name: "student-library",
    });
  else
    router.push({
      name: "admin-library",
    });
  };

  const levels = ref([]);
  const totalLevels = ref(0);
  const currentIndexLevel = ref(0);
  const classCurrentLevel = ref("level-a");

  const getCurrentLibrary = computed(() => {
    const library = store.getters["shared/getCurrentLibrary"];
    if (library.levels.length > 0) {
      levels.value = library.levels;
      currentIndexLevel.value = library.indexLevel;

      const level = library.levels[library.indexLevel];
      const classLevel = level.level_name_short.toLowerCase();
      classCurrentLevel.value= "level-" + classLevel;

      totalLevels.value = library.levels.length - 1;
    }
    return library;
  });

  const showLevel = (action) => {
    showLibrary.value = false;
    if (action === "next") currentIndexLevel.value += 1;
    else if (action === "previous") currentIndexLevel.value -= 1;
    updateLevel(currentIndexLevel.value);
    setTimeout(() => {
      showLibrary.value = true;
    },500)
  };

  const updateLevel = (index) => {
    const level = levels.value[index];
    classCurrentLevel.value =
      "level-" + level.level_name_short[0].toLowerCase();

    store.commit("shared/updateCurrentLevel", {
      currentLevel: level,
      indexLevel: index,
      currentLevelNext: levels.value[index + 1],
    });
  };



  return {
    getCurrentLibrary,
    currentIndexLevel,
    totalLevels,
    showLevel,
    classCurrentLevel,
    goLevelContent,
    showLibrary,
    goPreviousLibrary,
  };
};

export default useCurrentLibrary;
