import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
const useLibrary = () =>{
    const store = useStore();
    const router = useRouter()
    onBeforeMount(() => {
        store.commit('shared/setCalendarEvents', []);
        const library = store.getters["shared/getCurrentLibrary"];
        if(library.isLoading)
          router.push({
            name: "teacher-library",
          });
      })
    const loadServices = async () => {
        await store.dispatch("teachers/libraryLevels");
        await store.dispatch("teachers/dashboardStart");

    }

    return {
        loadServices
    }
}

export default useLibrary