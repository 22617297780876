import { onBeforeMount, computed, watchEffect, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { jsPDF } from "jspdf";
import  html2canvas from "html2canvas"
export const useLibrary = () => {
  const store = useStore();
  const router = useRouter();
  const dataProgress = ref([]);
  onBeforeMount(() => {
    const library = store.getters["shared/getCurrentLibrary"];
    if (library.isLoading)
      router.push({
        name: "admin-library",
      });
  });
  const loadServices = async () => {
    const library = store.getters["shared/getCurrentLibrary"];
    const { contentAdd }= store.getters["admins/myLibraryModal"]
    if(library.levels.length === 0 || contentAdd.isUpdate){
      if(contentAdd.isUpdate)
        store.commit("shared/updateLibrary", { isLoading:true })
      await store.dispatch("admins/getLibrary",{levelIdUpdate: contentAdd.levelIdUpdate});
    }
  };

  const progressStudents = computed(() => {
    return store.getters["admins/progressStudents"];
  });

  const setProgressData = (data) => {
    dataProgress.value = [];
    const library = store.getters["shared/getCurrentLibrary"];
    let levels = library.levels;
    Object.entries(data).forEach(([key, value],index) => {
      let item = {
        name: "",
        value: value,
        class: "",
        progress_class : "",
        total:0
      };
      let nameArray = key.split("_");

      if(key !== "total_students"){
        item.name = nameArray.map((item, index) => {
          if (index == 0) return item.charAt(0).toUpperCase() + item.slice(1);
          return item.charAt(0).toUpperCase() + item.slice(1);
        });
        let new_name;
        item.name.forEach((item) => {
          new_name = new_name ? new_name + " " + item : item;
        })
        item.name = new_name
        if(key !== "not_started"){
          item.class = nameArray.map((item, index) => {
            if (index == 0) return item.charAt(0).toLowerCase() + item.slice(1).toLowerCase();
            return item.charAt(0).toLowerCase() + item.slice(1).toLowerCase();
          });
          let new_class;
          item.class.forEach((item) => {
            new_class = new_class ? new_class + "-" + item : item;
          })
          item.class = new_class
          let new_class_array = new_class.split("-");
          item.progress_class = "progress-" + new_class_array[1]
          levels[index-1].level_content.forEach(content => {
            item.total += content.title_content.length
          })
        }else{
          levels.forEach(level => {
            level.level_content.forEach(content => {
              item.total += content.title_content.length
            })
          })
        }
        dataProgress.value.push(item);
      }
    });
  };



  const openToast = (message, status) => {
    const notification = { isOpen: true };
    notification.message = message;
    notification.status = status;
    store.commit("shared/uiNotificationStatus", notification);
    store.dispatch("shared/resetNotificationStatus");
  };

  const toPdfProgress = () => {
    const doc = new jsPDF('p','pt','letter');
    const progress = document.querySelector('#progress-student-content');
    const margin = 10;
    const scale = 1;
    doc.html(progress,{
      x:doc.internal.pageSize.width/3.5,
      y:margin,
      background:"#fff",
      html2canvas: { scale },
      callback: function(doc) {
        openToast("The charts selected have been exported successfully.","success")
        doc.save('progress-student-content.pdf')
      }
    })
  } 

  watchEffect(() => {
    let { data } = store.getters["admins/progressStudents"];
    if (Object.entries(data).length > 0) {
      setProgressData(data);
    }
  });

  return {
    loadServices,
    toPdfProgress,
    progressStudents,
    dataProgress
  };
};

export default useLibrary;
